import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizDef1', 'quizDef2', 'quizDef3', 'quizDef4', 'quizDef5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TextCustom = makeShortcode("TextCustom");
const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "effective-problem-solving"
    }}>{`Effective problem-solving`}</h1>
    <p>{`Here are six problem-solving steps you can share with your teenager to help them deal with life’s problems.`}</p>
    <h3 {...{
      "id": "1-define-the-problem"
    }}>{`1. Define the problem.`}</h3>
    <p>{`Help your teenager to identify and clearly state the problem that they are facing. Encourage them to be specific about the behaviour, situation, timing, and circumstances that make it a problem.`}</p>
    <p>{`Your teenager may need help `}<strong parentName="p">{`breaking down their problem`}</strong>{` into smaller, more manageable pieces, before addressing them one at a time.`}</p>
    <TextCustom component="span" variant="h5" mdxType="TextCustom">
  From the examples below, which ones do you think have been defined well?
  <h6> (pick a response for each example to proceed)</h6>
    </TextCustom>
    <Paper mdxType="Paper">
  <Quiz id="quizDef1" singleAnswer={true} question="I have no friends." answers={[{
        name: 'Yes',
        correct: false
      }, {
        name: 'No',
        correct: true
      }]} mdxType="Quiz" />
  <Quiz id="quizDef2" singleAnswer={true} question="Playing soccer for a club and the school means that I don't have enough time for school work." answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
  <Quiz id="quizDef3" singleAnswer={true} question="I don't want to do art anymore because my teacher hates me." answers={[{
        name: 'Yes',
        correct: false
      }, {
        name: 'No',
        correct: true
      }]} mdxType="Quiz" />
  <Quiz id="quizDef4" singleAnswer={true} question="I need to improve my marks in Maths in the next 2 months, otherwise I will not pass the subject." answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
  <Quiz id="quizDef5" singleAnswer={true} question="I am bad at Science." answers={[{
        name: 'Yes',
        correct: false
      }, {
        name: 'No',
        correct: true
      }]} mdxType="Quiz" />
    </Paper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      